import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { COLORS } from '../utils/constants';

import { LoadingElement } from './utils';
import useStyles from './Modal.styles';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: COLORS.darkBlue,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: COLORS.darkBlue,
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: COLORS.darkBlue,
      },
    },
  },
})(TextField);

interface ModalMigrateProps {
  handleApprove: () => void;
  handleMigrate: (value: string) => void;
  isContractApproved: boolean;
  isLoading: boolean;
  loadingModalMessage: string;
  migrateLink: string;
  onClose: () => void;
  open: boolean;
  setMigrateLink: (value: string) => void;
}

function ModalMigrate({
  handleApprove,
  handleMigrate,
  isContractApproved,
  isLoading,
  loadingModalMessage,
  migrateLink,
  onClose,
  open,
  setMigrateLink,
}: ModalMigrateProps) {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const handleChangeLinkText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMigrateLink(e.target.value);
  };

  return (
    <Dialog
      onClose={!isLoading ? handleClose : () => null}
      aria-labelledby="migrate-title"
      open={open}
      fullWidth={true}
    >
      <DialogTitle id="migrate-title" className={classes.modalTitle}>
        Migrating a NFTheo
      </DialogTitle>

      <DialogContent className={classes.modalContent}>
        <Grid className={isContractApproved ? classes.modalDescription : ''}>
          {isContractApproved
            ? 'In order to migrate your NFTheo, just copy and paste the OpenSea address here.'
            : 'In order to get started, you must first approve the new contract to communicate with the existing contract on your behalf.'}
        </Grid>

        {isContractApproved && (
          <CssTextField
            id="outlined-basic"
            size="small"
            label="OpenSea URL"
            variant="outlined"
            placeholder="Copy and paste the OpenSea URL here"
            multiline
            value={migrateLink}
            onChange={handleChangeLinkText}
            className={classes.textInput}
            disabled={isLoading}
          />
        )}
        {isLoading && (
          <p className={classes.loadingText}>{loadingModalMessage}</p>
        )}
      </DialogContent>
      <DialogActions className={classes.actionContainer}>
        {isContractApproved ? (
          <Button
            onClick={() => handleMigrate(migrateLink)}
            className={classes.submitButton}
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {isLoading && <LoadingElement />} Migrate
          </Button>
        ) : (
          <Button
            onClick={() => handleApprove()}
            className={classes.submitButton}
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {isLoading && <LoadingElement />} Approve new Contract
          </Button>
        )}
        <Button
          onClick={handleClose}
          className={classes.cancelButton}
          disabled={isLoading}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalMigrate;
