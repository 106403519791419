import { useEffect, useState } from 'react';
import { providers, BigNumber, Contract } from 'ethers';

import nftheoAbi from '../interfaces/nftheo.abi';
import nftheoNewAbi from '../interfaces/nftheo-new.abi';
import { ORIGINAL_CONTRACT_ADDRESS, NEW_CONTRACT_ADDRESS } from '../env';

/**
 * Check MetaMask settings
 *
 */
const _isMetaMaskInstalled = (): boolean => {
  // Have to check the ethereum binding on the window object to see if it's installed
  const { ethereum } = window as any;
  return Boolean(ethereum && ethereum.isMetaMask);
};

/**
 * Get metamask as provider if it exists
 */
const _getProvider = (): providers.Web3Provider | null => {
  if (!_isMetaMaskInstalled()) {
    return null;
  }
  return new providers.Web3Provider((window as any).ethereum);
};

/**
 * Get the id of the chain the user is connected to
 */
export const _getChainId = async (): Promise<string> => {
  const provider = _getProvider();
  if (!provider) {
    return '-1';
  }
  const network = await provider.getNetwork();
  return String(network.chainId);
};

/**
 * Listen for MetaMask account change and invoke callback when it changes
 *
 * @param callback method to invoke when account changes
 */
const _onAccountChange = (callback: (accounts: string[]) => void): void => {
  if (!_isMetaMaskInstalled()) {
    return;
  }
  (window as any).ethereum.on('accountsChanged', callback);
};

/**
 * Listen for MetaMask chain id change and invoke callback when it changes
 *
 * @param callback method to invoke when chain changes
 */
const _onNetworkChange = (callback: (id: string) => void): void => {
  if (!_isMetaMaskInstalled()) {
    return;
  }
  (window as any).ethereum.on('chainChanged', callback);
};

/**
 * A use effect that pulls in the address and chain id
 *
 */
export const useNetworkAddress = () => {
  const [userWalletAddress, setUserWalletAddress] = useState<string | null>(
    null
  );
  const [chainId, setChainId] = useState<string | null>(null);

  useEffect(() => {
    const loadConnection = async () => {
      try {
        const addressId = await getAddress();
        if (addressId) {
          setUserWalletAddress(addressId.toLowerCase());
        }
        setChainId(String(await _getChainId()));
      } catch (error) {
        console.log(error);
        return error;
      }
    };
    _onAccountChange((_address) => {
      setUserWalletAddress(_address[0]);
    });
    _onNetworkChange((_network) => {
      setChainId(String(_network));
    });
    loadConnection();
  }, []);

  return {
    userWalletAddress,
    chainId,
  };
};

interface ConnectMetaMaskResponse {
  result: boolean;
  message: string;
}
/**
 * Connect Metamask wallet to page
 */
export const connectMetamask = async (): Promise<ConnectMetaMaskResponse> => {
  if (!_isMetaMaskInstalled()) {
    return {
      result: false,
      message: 'Metamask is not installed. Please download MetaMask.',
    };
  }
  try {
    // Will Start the MetaMask Extension
    await (window as any).ethereum.request({ method: 'eth_requestAccounts' });
    return {
      result: true,
      message: 'Successfully connected MetaMask.',
    };
  } catch (error) {
    console.log(error);
    let message =
      'Request error in MetaMask. Please open MetaMask and check there.';

    if (!!error && !!error.code) {
      if (error.code === 4001) {
        message =
          'MetaMask request rejected. Please click connect again and approve on MetaMask.';
      } else if (error.code === -32002) {
        message =
          'There is a already a MetaMask connection request. Please open MetaMask and check there.';
      } else {
        const contractErrorMessage =
          'data' in error &&
          !!error.data &&
          'message' in error.data &&
          !!error.data.message
            ? error.data.message
            : error.message;
        message = `Contract error - ${contractErrorMessage}`;
      }
    }
    return {
      result: false,
      message,
    };
  }
};

/**
 * Returns the contract
 */
const _getContract = (isOriginal: boolean = true) => {
  const provider = _getProvider();
  if (!provider) {
    return {
      message: 'Unable to connect to MetaMask. Wallet not connected.',
      contract: null,
    };
  }
  const contractAddress = isOriginal
    ? ORIGINAL_CONTRACT_ADDRESS
    : NEW_CONTRACT_ADDRESS;
  const abiToUse = isOriginal ? nftheoAbi : nftheoNewAbi;
  const signer = provider.getSigner();
  // const chainId = await _getChainId();
  const contract = new Contract(contractAddress, abiToUse, signer);

  return {
    message: 'Success',
    contract,
  };
};

/**
 * Get if the original contract is approved
 */
export const getIsApproved = async (userWalletAddress: string) => {
  const { message, contract } = _getContract();

  if (!!contract) {
    const isOldContractApproved = await contract.isApprovedForAll(
      userWalletAddress,
      NEW_CONTRACT_ADDRESS
    );
    return {
      result: true,
      message: 'Success.',
      isOldContractApproved,
    };
  }
  return {
    result: false,
    message,
    isOldContractApproved: null,
  };
};

// Approve contract
export const setIsApproved = async () => {
  const { message, contract: oldContract } = _getContract();

  if (!!oldContract) {
    // Unable to connect to MetaMask. Wallet not connected.
    try {
      const transactionDetails = await oldContract.setApprovalForAll(
        NEW_CONTRACT_ADDRESS,
        true
      );
      return {
        result: true,
        message: 'Success',
        transactionDetails,
      };
    } catch (error) {
      console.log(error);
      let message =
        'Request error in MetaMask. Please open MetaMask and check there.';

      if (!!error && !!error.code) {
        if (error.code === 4001) {
          message =
            'MetaMask request rejected. Please click connect again and approve on MetaMask.';
        } else if (error.code === -32002) {
          message =
            'There is a already a MetaMask connection request. Please open MetaMask and check there.';
        } else {
          const contractErrorMessage =
            'data' in error &&
            !!error.data &&
            'message' in error.data &&
            !!error.data.message
              ? error.data.message
              : error.message;
          message = `Contract error - ${contractErrorMessage}`;
        }
      }
      return {
        result: false,
        message,
        transactionDetails: null,
      };
    }
  }

  return {
    result: false,
    message,
    transactionDetails: null,
  };
};

/**
 * Returns Wallet address if one is connected to the site, otherwise null
 */
export const getAddress = async (): Promise<string | null> => {
  const provider = _getProvider();
  if (!provider) {
    return null;
  }
  try {
    const accounts = await provider.listAccounts();
    return accounts.length > 0 ? accounts[0] : null;
  } catch (e) {
    return null;
  }
};

/**
 *
 * @param transactionHash transaction hash
 */
export const watchTransaction = (
  transactionHash: string,
  callback: any
): void => {
  const provider = _getProvider();
  if (!provider) {
    return;
  }
  provider.once(transactionHash, (transaction) => {
    callback(transaction, transaction.status === 1);
  });
};

// Migrate old Theos
export const migrateTheo = async (migratedTheoId: string) => {
  const { message, contract: newContract } = _getContract(false);
  const bigBoy = BigNumber.from(migratedTheoId);

  if (!!newContract) {
    // Unable to connect to MetaMask. Wallet not connected.
    try {
      const transactionDetails = await newContract.redeem(bigBoy);
      return {
        result: true,
        message: 'Success',
        transactionDetails,
      };
    } catch (error) {
      console.log(error);
      let message =
        'Request error in MetaMask. Please open MetaMask and check there.';

      if (!!error && !!error.code) {
        if (error.code === 4001) {
          message =
            'MetaMask request rejected. Please click connect again and approve on MetaMask.';
        } else {
          const contractErrorMessage =
            'data' in error &&
            !!error.data &&
            'message' in error.data &&
            !!error.data.message
              ? error.data.message
              : error.message;
          message = `Contract error - ${contractErrorMessage}`;
        }
      }
      return {
        result: false,
        message,
        transactionDetails: null,
      };
    }
  }

  return {
    result: false,
    message,
    transactionDetails: null,
  };
};

const mintTheoCost = async () => {
  const { message, contract: newContract } = _getContract(false);

  if (!!newContract) {
    try {
      const theoCost = await newContract.nftCost();

      return {
        result: true,
        message: 'Success.',
        cost: theoCost,
      };
    } catch (error) {
      console.log(error);
      let message =
        'Request error in MetaMask. Please open MetaMask and check there.';
      // Can only mint once early
      if (!!error && !!error.code) {
        if (error.code === 4001) {
          message =
            'MetaMask request rejected. Please click connect again and approve on MetaMask.';
        } else {
          const contractErrorMessage =
            'data' in error &&
            !!error.data &&
            'message' in error.data &&
            !!error.data.message
              ? error.data.message
              : error.message;
          message = `Contract error - ${contractErrorMessage}`;

          if (message.indexOf('Can only mint once early') > -1) {
            message = `Contract error - You have already minted once during the presale.`;
          }
        }
      }
      return {
        result: false,
        message,
        transactionDetails: null,
      };
    }
  }

  return {
    result: false,
    message,
    cost: null,
  };
};

// Mint Theos
export const mintTheo = async (amount: number) => {
  const { message, contract: newContract } = _getContract(false);

  if (!!newContract) {
    // Unable to connect to MetaMask. Wallet not connected.
    try {
      const { cost, result, message } = await mintTheoCost();
      if (result) {
        const currentMintTheoCost = BigNumber.from(amount).mul(cost);
        let currentGasCost = '150000';

        if (amount > 1) {
          currentGasCost = `${amount}00000`;
        }
        console.log(`MintTheoCost: ${currentMintTheoCost}`);
        console.log(`GasCost: ${currentGasCost}`);
        const transactionDetails = await newContract.mint(amount, {
          value: currentMintTheoCost,
          gasLimit: currentGasCost,
        });
        return {
          result: true,
          message: 'Success',
          transactionDetails,
        };
      }

      return {
        result: false,
        message,
        transactionDetails: null,
      };
    } catch (error) {
      console.log(error);
      let message =
        'Request error in MetaMask. Please open MetaMask and check there.';

      if (!!error && !!error.code) {
        if (error.code === 4001) {
          message =
            'MetaMask request rejected. Please click connect again and approve on MetaMask.';
        } else {
          const contractErrorMessage =
            'data' in error &&
            !!error.data &&
            'message' in error.data &&
            !!error.data.message
              ? error.data.message
              : error.message;
          message = `Contract error - ${contractErrorMessage}`;

          if (message.indexOf('Can only mint once early') > -1) {
            message = `Contract error - You have already minted once during the presale.`;
          }
        }
      }
      return {
        result: false,
        message,
        transactionDetails: null,
      };
    }
  }

  return {
    result: false,
    message,
    transactionDetails: null,
  };
};

// // Example of calling a "GET" function
// export const viewCallExample = async () => {
//   const provider = _getProvider();
//   if (!provider) {
//     throw new Error('Unable to connect. Wallet not connected.');
//   }
//   const chainId = await _getChainId();
//   const smartContract = new Contract(
//     CONTRACT_ADDRESSES[chainId],
//     nftheoAbi,
//     provider.getSigner()
//   );
//   return await smartContract.viewFUNCTION();
// };
