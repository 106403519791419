import { createStyles, makeStyles } from '@material-ui/core/styles';

import { COLORS } from './utils/constants';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      position: 'fixed',
      backgroundColor: COLORS.red,
      flexGrow: 1,
      color: 'white',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
    },
    appBar: {
      position: 'fixed',
      width: '100%',
      boxShadow: 'none',
      color: 'rgb(37,38,61)',
    },
    content: {
      height: '100%',
      margin: 'auto',
    },
    contentHalf: {
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
    },
    shadowImage: {
      position: 'absolute',
      width: 'calc(100%)',
    },
    logoContainer: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
    socialContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
    },
    logoImage: {
      width: '80px',
    },
    socialImage: {
      width: '30px',
      padding: '5px',
    },
    loading: {
      marginRight: theme.spacing(1),
    },
    migrateButton: {
      fontFamily: 'Alphakind',
      color: 'white',
      backgroundColor: COLORS.darkBlue,
      borderRadius: '30px',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: COLORS.darkBlueHover,
      },
    },
    mintButtonContainer: {
      textAlign: 'center',
      marginTop: theme.spacing(3),
    },
    mintButton: {
      fontFamily: 'Alphakind',
      color: COLORS.darkBlue,
      backgroundColor: 'white',
      borderRadius: '30px',
      textTransform: 'none',
    },
    walletButton: {
      fontFamily: 'Alphakind',
      color: 'white',
      marginRight: theme.spacing(1),
      textTransform: 'none',
    },
    headerTitle: {
      fontFamily: 'Alphakind',
      color: 'white',
    },
    title: {
      fontFamily: 'Alphakind',
      color: 'white',
      textShadow: `3px 3px ${COLORS.darkBlue}`,
    },
    bodyTextContainer: {
      margin: `${theme.spacing(3)}px 0`,
    },
    bodyText: {
      fontFamily: 'Alphakind',
      color: 'white',
      marginTop: theme.spacing(3),
    },
    walletDetailsContainer: {
      color: 'white',
      justifyContent: 'flex-end',
      wordBreak: 'break-word',
    },
    walletDetailsTitle: {
      textShadow: '1px 1px #22273f',
      marginRight: theme.spacing(1),
    },
  })
);

export default useStyles;
