import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import { IS_EARLY_MINT, IS_SOLD_OUT, MAX_MINT_COUNT } from '../env';

import useStyles from './Modal.styles';

interface ModalMintProps {
  handleMint: (value: number) => void;
  isLoading: boolean;
  loadingModalMessage: string;
  onClose: () => void;
  open: boolean;
}

function ModalMint({
  handleMint,
  isLoading,
  loadingModalMessage,
  onClose,
  open,
}: ModalMintProps) {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value: number) => {
    handleMint(value);
  };
  const numberText = ['ONE', 'TWO', 'THREE', 'FOUR', 'FIVE'];

  return (
    <Dialog
      onClose={!isLoading ? handleClose : () => null}
      aria-labelledby="mint-title"
      open={open}
      fullWidth={true}
    >
      <DialogTitle id="mint-title" className={classes.modalTitle}>
        Minting NFTheos
      </DialogTitle>

      {isLoading ? (
        <p className={classes.loadingText}>{loadingModalMessage}</p>
      ) : (
        <>
          {IS_EARLY_MINT && (
            <DialogContent className={classes.modalContentWarning}>
              NOTE: While the presale is active, you can only do this once.
            </DialogContent>
          )}
          <DialogContent className={classes.modalMintContent}>
            How many Theos would you like to mint?
          </DialogContent>
          {!IS_SOLD_OUT ? (
            <List>
              {[...Array(MAX_MINT_COUNT)].map((_, index: number) => (
                <ListItem
                  button
                  onClick={() => handleListItemClick(index + 1)}
                  className={classes.buttonListElement}
                  key={`element-${index}`}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>{index + 1}</Avatar>
                  </ListItemAvatar>
                  <ListItemText className={classes.buttonTextElement}>
                    Mint <b>{numberText[index]}</b> Theo{!!index && 's'}
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          ) : (
            <Button
              className={classes.submitButton}
              size="large"
              variant="contained"
              onClick={() => {}}
              disabled={true}
            >
              Sold Out!
            </Button>
          )}
        </>
      )}
      <DialogActions className={classes.actionContainer}>
        <Button
          onClick={handleClose}
          className={classes.cancelButton}
          disabled={isLoading}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalMint;
