import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';

import useStyles from './App.styles';
import {
  ASSETS_URL_SUBFOLDER,
  CHAIN_ID,
  CHAIN_NAME,
  IS_PAGE_LIVE,
  IS_SOLD_OUT,
  MAX_MINT_COUNT,
  ORIGINAL_CONTRACT_ADDRESS,
} from './env';

import openseaIcon from './assets/icons/icon_opensea.png';
import discordIcon from './assets/icons/icon_discord.svg';
import twitterIcon from './assets/icons/icon_twitter.svg';

import bearImageColor from './assets/images/logo_transparent.png';
import bearShadow from './assets/images/bear_shadow.png';
import bearGif from './assets/images/theo_v2.gif';

import MigrateModal from './components/Migrate.modal';
import MintModal from './components/Mint.modal';
import { LoadingElement } from './components/utils';

import {
  connectMetamask,
  getIsApproved,
  migrateTheo,
  mintTheo,
  setIsApproved,
  useNetworkAddress,
  watchTransaction,
} from './utils/blockchain';
import { MODAL_TAGS } from './utils/constants';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function App() {
  const { userWalletAddress, chainId } = useNetworkAddress();
  const classes = useStyles();

  const [openModal, setOpenModal] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [isContractApproved, setIsContractApproved] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertType, setAlertType] = React.useState<Color>('warning');
  const [migrateLink, setMigrateLink] = React.useState('');
  const [loadingModalMessage, setLoadingModalMessage] = React.useState('');

  React.useEffect(() => {
    setOpenModal('');
    setIsLoading(false);
  }, [userWalletAddress, chainId]);

  const handleClickMigrateOpen = async () => {
    if (chainId !== CHAIN_ID) {
      setAlertType('warning');
      setAlertMessage(
        `You are not on the correct network. Please connect to the ${CHAIN_NAME}.`
      );
      setShowAlert(true);
    } else {
      setIsLoading(true);
      if (!userWalletAddress) {
        setAlertMessage('There needs to be a connected wallet.');
        setShowAlert(true);
      } else {
        const { isOldContractApproved, message, result } = await getIsApproved(
          userWalletAddress
        );

        if (result) {
          setIsContractApproved(isOldContractApproved);
          setOpenModal(MODAL_TAGS.migrate);
        } else {
          setAlertType('warning');
          setAlertMessage(message);
          setShowAlert(true);
        }
      }

      setIsLoading(false);
    }
  };

  const handleClickMintOpen = () => {
    console.log('chainId: ');
    console.log(chainId);
    console.log('CHAIN_ID: ');
    console.log(CHAIN_ID);
    if (chainId !== CHAIN_ID) {
      setAlertType('warning');
      setAlertMessage(
        `You are not on the correct network. Please connect to the ${CHAIN_NAME}.`
      );
      setShowAlert(true);
    } else {
      setOpenModal(MODAL_TAGS.mint);
    }
  };

  const handleApproveClose = async () => {
    setAlertType('warning');
    if (!userWalletAddress) {
      setAlertMessage('There needs to be a connected wallet.');
      setShowAlert(true);
    } else if (chainId !== CHAIN_ID) {
      setAlertMessage(
        `You are not on the correct network. Please connect to the ${CHAIN_NAME}.`
      );
      setShowAlert(true);
    } else {
      setIsLoading(true);

      const approvedStatus = await setIsApproved();
      const { message, result, transactionDetails } = approvedStatus;

      if (result) {
        // Pass transactiion to be watched
        const approveCallback = () => {
          setIsLoading(false);
          setAlertType('success');
          setAlertMessage('Approved new contract successful.');
          setShowAlert(true);
          setIsContractApproved(true);
        };

        watchTransaction(transactionDetails.hash, approveCallback);
      } else {
        // Show error message
        setAlertMessage(message);
        setShowAlert(true);
        setIsLoading(false);
      }
    }
  };

  const handleMigrateClose = async (migrateUrl: string) => {
    setIsLoading(true);
    let theoOpenSeaId = '';
    const assetText = ASSETS_URL_SUBFOLDER;
    const indexOfAssetText = migrateUrl.indexOf(assetText);
    const questionMark =
      migrateUrl.indexOf('?') > -1 ? migrateUrl.indexOf('?') : undefined;

    if (indexOfAssetText > 0) {
      theoOpenSeaId = migrateUrl.slice(
        indexOfAssetText + assetText.length,
        questionMark
      );
    }

    const theoOpenSeaIdSet = theoOpenSeaId.split('/');

    setAlertType('warning');
    if (chainId !== CHAIN_ID) {
      setAlertMessage(
        `You are not on the correct network. Please connect to the ${CHAIN_NAME}.`
      );
      setShowAlert(true);
      setIsLoading(false);
    } else if (
      !migrateUrl ||
      theoOpenSeaId.length === 0 ||
      !theoOpenSeaIdSet[0] ||
      !theoOpenSeaIdSet[1] ||
      theoOpenSeaIdSet[0].toLowerCase() !==
        ORIGINAL_CONTRACT_ADDRESS.toLowerCase()
    ) {
      setAlertMessage('Invalid OpenSea url.');
      setShowAlert(true);
      setIsLoading(false);
    } else if (!userWalletAddress) {
      setAlertMessage('There needs to be a connected wallet.');
      setShowAlert(true);
      setIsLoading(false);
    } else {
      setLoadingModalMessage('Waiting for MetaMask approval...');
      const cleanedId = theoOpenSeaIdSet[1].replace(/\n/g, '');
      const migrateTheoTransaction = await migrateTheo(cleanedId);
      setLoadingModalMessage('Waiting for block confirmations...');

      const { message, result, transactionDetails } = migrateTheoTransaction;

      if (result) {
        // Pass transactiion to be watched
        const completeTheoMigrateCallback = () => {
          setIsLoading(false);
          setAlertType('success');
          setAlertMessage('Your NFTheo has been successfully migrated!');
          setOpenModal('');
          setMigrateLink('');
          setShowAlert(true);
        };

        watchTransaction(transactionDetails.hash, completeTheoMigrateCallback);
      } else {
        // Show error message
        setAlertMessage(message);
        setShowAlert(true);
        setIsLoading(false);
      }
    }
  };

  const handleMintClose = async (mintCount: number) => {
    setIsLoading(true);

    setAlertType('warning');
    if (chainId !== CHAIN_ID) {
      setAlertMessage(
        `You are not on the correct network. Please connect to the ${CHAIN_NAME}.`
      );
      setShowAlert(true);
      setIsLoading(false);
    } else if (mintCount < 1 || mintCount > MAX_MINT_COUNT) {
      setAlertMessage(
        'You must mint between 1 and MAX_MINT_COUNT (5 at time of deploy).'
      );
      setShowAlert(true);
      setIsLoading(false);
    } else if (!userWalletAddress) {
      setAlertMessage('There needs to be a connected wallet.');
      setShowAlert(true);
      setIsLoading(false);
    } else {
      setLoadingModalMessage('Waiting for MetaMask approval...');

      const mintTheoTransaction = await mintTheo(mintCount);
      setLoadingModalMessage('Waiting for block confirmations...');

      const { message, result, transactionDetails } = mintTheoTransaction;

      if (result) {
        // Pass transactiion to be watched
        const completeTheoMintCallback = () => {
          setIsLoading(false);
          setAlertType('success');
          setAlertMessage(
            'Your NFTheo has been successfully minted! Head over to OpenSea to see it!'
          );
          setOpenModal('');
          setMigrateLink('');
          setShowAlert(true);
        };

        watchTransaction(transactionDetails.hash, completeTheoMintCallback);
      } else {
        // Show error message
        setAlertMessage(message);
        setShowAlert(true);
        setIsLoading(false);
      }
    }
  };

  const handleClickWallet = async () => {
    const { result, message } = await connectMetamask();

    if (!result) {
      setAlertType('warning');
    } else {
      setAlertType('success');
    }
    setAlertMessage(message);
    setShowAlert(true);
  };

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowAlert(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <div className={classes.root}>
        <AppBar
          position="static"
          color="transparent"
          className={classes.appBar}
        >
          <Toolbar>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={4}>
                {IS_PAGE_LIVE && (
                  <Button
                    className={classes.migrateButton}
                    size="medium"
                    variant="contained"
                    onClick={handleClickMigrateOpen}
                    disabled={!userWalletAddress || isLoading}
                  >
                    {isLoading && <LoadingElement />} Migrate your Genesis Theo
                  </Button>
                )}
              </Grid>
              <Grid item xs={4}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className={classes.logoContainer}
                >
                  <img
                    src={bearImageColor}
                    alt="TheoBear logo"
                    className={classes.logoImage}
                  />
                  <Typography variant="h5" className={classes.headerTitle}>
                    NFTheo
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className={classes.socialContainer}
                >
                  <a
                    href="https://opensea.io/collection/theo-nft"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={openseaIcon}
                      alt="Opensea Icon"
                      className={classes.socialImage}
                    />
                  </a>
                  <a
                    href="https://discord.com/invite/WFMAwPTT2U"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={discordIcon}
                      alt="Discord Icon"
                      className={classes.socialImage}
                    />
                  </a>
                  <a
                    href="https://twitter.com/TheoNFT"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={twitterIcon}
                      alt="Twitter Icon"
                      className={classes.socialImage}
                    />
                  </a>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid
                  container
                  direction="row"
                  className={classes.walletDetailsContainer}
                >
                  {!!userWalletAddress ? (
                    <>
                      <span className={classes.walletDetailsTitle}>
                        Wallet:
                      </span>{' '}
                      {userWalletAddress}
                    </>
                  ) : (
                    'No wallet found'
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <div className="overall-container">
          <Scrollbars>
            <div className="internal-container">
              <Grid
                className={classes.content}
                container
                item
                direction="row"
                alignItems="stretch"
                xs={11}
                sm={11}
                md={10}
                lg={10}
              >
                <Grid
                  className={classes.contentHalf}
                  direction="column"
                  item
                  xs={12}
                  sm={7}
                >
                  <Typography variant="h3" className={classes.title}>
                    Join the Teddy Bear Picnic
                  </Typography>
                  <Grid className={classes.bodyTextContainer}>
                    <Typography variant="h6" className={classes.bodyText}>
                      NFTheo is a collection of 3000 unique Teddy Bears stored
                      on the Blockchain.
                    </Typography>
                    <Typography variant="h6" className={classes.bodyText}>
                      Every Theo is one of a kind.
                    </Typography>
                    <Typography variant="h6" className={classes.bodyText}>
                      When you mint you'll be in with a chance of collecting a
                      Generation 2 Theo, a rarer Themed Theo, or a Legendary
                      animated Theo.
                    </Typography>
                  </Grid>
                  {IS_PAGE_LIVE && (
                    <Grid className={classes.mintButtonContainer}>
                      {!!userWalletAddress ? (
                        <>
                          {!IS_SOLD_OUT ? (
                            <Button
                              className={classes.mintButton}
                              size="large"
                              variant="contained"
                              onClick={handleClickMintOpen}
                              disabled={!userWalletAddress || isLoading}
                            >
                              {isLoading && <LoadingElement />} Mint a Theo
                            </Button>
                          ) : (
                            <Button
                              className={classes.mintButton}
                              size="large"
                              variant="contained"
                              onClick={() => {}}
                              disabled={true}
                            >
                              Sold Out!
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button
                          className={classes.mintButton}
                          size="large"
                          variant="contained"
                          onClick={handleClickWallet}
                          disabled={isLoading}
                        >
                          {isLoading && <LoadingElement />} Connect MetaMask
                        </Button>
                      )}
                    </Grid>
                  )}
                </Grid>
                <Grid
                  className={classes.contentHalf}
                  direction="column"
                  item
                  xs={12}
                  sm={5}
                >
                  <img src={bearGif} alt="TheoBear GIF" />
                  <img
                    src={bearShadow}
                    alt="TheoBear Shadow GIF"
                    className={classes.shadowImage}
                  />
                </Grid>
              </Grid>
            </div>
          </Scrollbars>
        </div>
      </div>
      <MigrateModal
        handleApprove={handleApproveClose}
        handleMigrate={handleMigrateClose}
        isContractApproved={isContractApproved}
        isLoading={isLoading}
        loadingModalMessage={loadingModalMessage}
        migrateLink={migrateLink}
        onClose={() => setOpenModal('')}
        open={openModal === MODAL_TAGS.migrate}
        setMigrateLink={setMigrateLink}
      />
      <MintModal
        handleMint={handleMintClose}
        isLoading={isLoading}
        loadingModalMessage={loadingModalMessage}
        onClose={() => setOpenModal('')}
        open={openModal === MODAL_TAGS.mint}
      />
    </>
  );
}

export default App;
