const IS_PROD =
  !!process.env.REACT_APP_APP_ENV && process.env.REACT_APP_APP_ENV === 'prod';

// Page content should show
export const IS_SOLD_OUT =
  !!process.env.REACT_APP_IS_SOLD_OUT &&
  process.env.REACT_APP_IS_SOLD_OUT === 'yes';

// Page content should show
export const IS_PAGE_LIVE =
  !!process.env.REACT_APP_IS_PAGE_LIVE &&
  process.env.REACT_APP_IS_PAGE_LIVE === 'yes';

// Mint count
export const IS_EARLY_MINT =
  !!process.env.REACT_APP_IS_EARLY_MINT &&
  process.env.REACT_APP_IS_EARLY_MINT === 'yes';

const MAX_EARLY_MINT_COUNT = process.env.REACT_APP_MAX_EARLY_MINT_COUNT
  ? process.env.REACT_APP_MAX_EARLY_MINT_COUNT
  : '1';

const MAX_REGULAR_MINT_COUNT = process.env.REACT_APP_MAX_REGULAR_MINT_COUNT
  ? process.env.REACT_APP_MAX_REGULAR_MINT_COUNT
  : '5';

// Maximum mint count
export const MAX_MINT_COUNT = parseInt(
  IS_EARLY_MINT ? MAX_EARLY_MINT_COUNT : MAX_REGULAR_MINT_COUNT,
  10
);

// Subfolder
const TESTNET_ASSETS_URL_SUBFOLDER = process.env
  .REACT_APP_TESTNET_ASSETS_URL_SUBFOLDER
  ? process.env.REACT_APP_TESTNET_ASSETS_URL_SUBFOLDER
  : '';
const PROD_ASSETS_URL_SUBFOLDER = process.env
  .REACT_APP_PROD_ASSETS_URL_SUBFOLDER
  ? process.env.REACT_APP_PROD_ASSETS_URL_SUBFOLDER
  : '';
export const ASSETS_URL_SUBFOLDER = IS_PROD
  ? PROD_ASSETS_URL_SUBFOLDER
  : TESTNET_ASSETS_URL_SUBFOLDER;

// Chain ID
const TESTNET_CHAIN_ID = process.env.REACT_APP_TESTNET_CHAIN_ID
  ? process.env.REACT_APP_TESTNET_CHAIN_ID
  : '';
const PROD_CHAIN_ID = process.env.REACT_APP_PROD_CHAIN_ID
  ? process.env.REACT_APP_PROD_CHAIN_ID
  : '';
export const CHAIN_ID = IS_PROD ? PROD_CHAIN_ID : TESTNET_CHAIN_ID;

// Chain Name
const TESTNET_CHAIN_NAME = process.env.REACT_APP_TESTNET_CHAIN_NAME
  ? process.env.REACT_APP_TESTNET_CHAIN_NAME
  : '';
const PROD_CHAIN_NAME = process.env.REACT_APP_PROD_CHAIN_NAME
  ? process.env.REACT_APP_PROD_CHAIN_NAME
  : '';
export const CHAIN_NAME = IS_PROD ? PROD_CHAIN_NAME : TESTNET_CHAIN_NAME;

// Original contract address
const TESTNET_ORIGINAL_CONTRACT_ADDRESS = process.env
  .REACT_APP_TESTNET_ORIGINAL_CONTRACT_ADDRESS
  ? process.env.REACT_APP_TESTNET_ORIGINAL_CONTRACT_ADDRESS
  : '';

const PROD_ORIGINAL_CONTRACT_ADDRESS = process.env
  .REACT_APP_PROD_ORIGINAL_CONTRACT_ADDRESS
  ? process.env.REACT_APP_PROD_ORIGINAL_CONTRACT_ADDRESS
  : '';

export const ORIGINAL_CONTRACT_ADDRESS = IS_PROD
  ? PROD_ORIGINAL_CONTRACT_ADDRESS
  : TESTNET_ORIGINAL_CONTRACT_ADDRESS;

// New contract address
const TESTNET_NEW_CONTRACT_ADDRESS = process.env
  .REACT_APP_TESTNET_NEW_CONTRACT_ADDRESS
  ? process.env.REACT_APP_TESTNET_NEW_CONTRACT_ADDRESS
  : '';

const PROD_NEW_CONTRACT_ADDRESS = process.env
  .REACT_APP_PROD_NEW_CONTRACT_ADDRESS
  ? process.env.REACT_APP_PROD_NEW_CONTRACT_ADDRESS
  : '';
export const NEW_CONTRACT_ADDRESS = IS_PROD
  ? PROD_NEW_CONTRACT_ADDRESS
  : TESTNET_NEW_CONTRACT_ADDRESS;
