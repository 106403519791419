import React from 'react';
import ReactLoading from 'react-loading';

import Grid from '@material-ui/core/Grid';

import useStyles from '../App.styles';

export const LoadingElement = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.loading}>
      <ReactLoading
        type={'spokes'}
        color={'rgba(0, 0, 0, 0.5)'}
        height={'24px'}
        width={'24px'}
      />
    </Grid>
  );
};
