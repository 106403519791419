export const COLORS = {
  red: '#fb5846',
  darkBlue: '#22273f',
  darkBlueHover: '#303758',
  darkGray: '#8c8c8c',
  primaryBlue: '#5b8dd3',
  gray: '#dfe0e0',
};

export const MODAL_TAGS = {
  migrate: 'migrate',
  mint: 'mint',
};
