import { createStyles, makeStyles } from '@material-ui/core/styles';

import { blue } from '@material-ui/core/colors';

import { COLORS } from '../utils/constants';

const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      backgroundColor: blue[100],
      color: COLORS.primaryBlue,
      fontFamily: 'Alphakind',
    },
    modalTitle: {
      backgroundColor: COLORS.darkBlue,
      color: 'white',
      '& h2': {
        fontFamily: 'Alphakind',
      },
    },
    modalContent: {
      color: COLORS.darkGray,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    modalMintContent: {
      color: COLORS.darkGray,
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
    modalContentWarning: {
      color: COLORS.red,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    loadingText: {
      color: COLORS.darkGray,
      textAlign: 'center',
    },
    modalDescription: {
      marginBottom: theme.spacing(4),
    },
    buttonListElement: {
      paddingLeft: '25% !important',
    },
    buttonTextElement: {
      '& span': {
        color: COLORS.darkBlue,
      },
    },
    buttonCloseContainerElement: {
      '& span': {
        color: COLORS.darkBlue,
      },
    },
    buttonCloseTextElement: {
      '& span': {
        color: COLORS.gray,
      },
    },
    cancelButton: {
      borderRadius: '30px',

      '& span': {
        color: COLORS.darkBlue,
      },
      '&:disabled': {
        '& span': {
          color: COLORS.gray,
        },
      },
    },
    submitButton: {
      borderRadius: '30px',
      backgroundColor: COLORS.darkBlue,
      '& span': {
        color: 'white',
      },
    },
    actionContainer: {
      justifyContent: 'center',
    },
    textInput: {
      width: '100%',
      borderRadius: '50%',
    },
  })
);

export default useStyles;
